<template>
  <div id="app">
    <div class="container">

      <div class="content-left">
        <div class="menu" v-for="(item,index) in menuList" @click="selectMenu(item)" :style="item.name == currentVal.name?'color:#d3b548':''">
          <i class="el-icon-s-claim icon"></i>
          <span>{{item.name}}</span>
        </div>
      </div>
      <div class="content-right">
        <NoticeSysManagement v-if="currentVal.name == '通知系统管理'" />
        <NoticeTypeManagement v-if="currentVal.name == '通知类型管理'" />
        <NoticeTypeAllot v-if="currentVal.name == '通知类型分配'" />
      </div>

    </div>
  </div>
</template>

<script>
import NoticeSysManagement from '@/views/management/NoticeSysManagement'
import NoticeTypeManagement from '@/views/management/NoticeTypeManagement'
import NoticeTypeAllot from '@/views/management/NoticeTypeAllot'
export default {
  name: 'JurisManagement',
  components: {
    NoticeSysManagement, NoticeTypeManagement, NoticeTypeAllot
  },
  data() {
    return {
      currentVal: {},
      menuList: [{
        name: '通知系统管理',
        path: '/NoticeSysManagement'
      }, {
        name: '通知类型管理',
        path: '/NoticeTypeManagement'
      }, {
        name: '通知类型分配',
        path: '/NoticeTypeAllot'
      }],
    }
  },
  created() {
    this.currentVal = this.menuList[0]

  },
  methods: {
    selectMenu(value) {
      this.currentVal = value
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;

  .content-left {
    width: 13%;
    height: 100%;
    background: rgba(10, 16, 29, 0);

    .menu {
      overflow: hidden;
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      padding: 0 15px;
      display: block;

      .icon {
        display: inline-block;
        padding: 0 10px;
      }
    }
    .menu:hover {
      background: rgb(24, 20, 90);
    }
  }

  .content-right {
    box-sizing: border-box;
    width: 87%;
  }
}
</style>