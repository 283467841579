import request from '@/../utils/request'
const api_name = '/iotechserver/notifysmallsystem'

export default {

    // 查询报警类型列表
    getNotifySmallSystemById() {
        return request({
            url: `${api_name}/getNotifySmallSystemById`,
            method: 'get',
        })
    },
    // 添加报警类型
    addNotifySmallSystem(smallSystemName) {
        return request({
            url: `${api_name}/addNotifySmallSystem`,
            method: 'post',
            data: { smallSystemName: smallSystemName },
        })
    },

    // 删除
    deleteNotifySmallSystem(id) {
        return request({
            url: `${api_name}/deleteNotifySmallSystem/${id}`,
            method: 'delete',
        })
    }


}