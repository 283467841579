<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="报警类型管理"> </el-page-header>
      </el-col>
    </el-row> -->

    <el-row :gutter="20">
      <el-button icon="el-icon-plus" type="success" @click="dialogFormVisible = true" class="add">添加</el-button>
    </el-row>

    <el-dialog title="添加信息" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="35%">
      <el-form :model="form">
        <el-form-item label="报警类型名称">
          <el-input v-model="form.info" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="appendInfo">确 定</el-button>
      </div>
    </el-dialog>

    <el-table ref="singleTable" :data="noticeTypeList" highlight-current-row style="width: 100%" height="700">
      <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
      <el-table-column prop="smallSystemName" property="date" label="报警类型名称" align="center">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="deleteNoticeType(scope.row.id)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// 报警类型列表
import noticeTypeManagement from "@/api/managementApi/NoticeTypeManagement"

export default {
  data() {
    return {
      dialogFormVisible: false,

      form: {
        info: "",
      },
      noticeTypeList: [], // table 中显示的报警类型

      alarmTypeInfo: {
        id: '',
        createTime: '',
        smallSystemName: ''    // 报警类型名称
      }
    };
  },
  created() {
    this.getNoticeTypeList(); // 获取报警类型
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    // 获取所有报警类型
    getNoticeTypeList() {
      noticeTypeManagement.getNotifySmallSystemById().then((res) => {
        console.log(res);
        this.noticeTypeList = res.data.data.list;
      })
    },

    // 添加权限类型名称
    appendInfo() {
      if (this.form.info != "") {
        noticeTypeManagement.addNotifySmallSystem(this.form.info).then((res) => {
          this.dialogFormVisible = false;
          this.getNoticeTypeList();
        })
      } else {
        this.$message.error('报警类型名称不能为空！');
      }
    },


    // 删除报警类型
    deleteNoticeType(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        noticeTypeManagement.deleteNotifySmallSystem(id).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getNoticeTypeList();
        });
      });
    },

  },
};
</script>

<style lang="scss" scoped>
#app{
  box-sizing: border-box;
  padding: 0 5px;
}
.add {
  margin: 10px 10px;
}
</style>